import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

function Membership() {
  return (
    <Layout>
      <SEO title="Membership" />
    </Layout>
  )
}

export default Membership
